import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import Favicon from "react-favicon";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const tagManagerArgs = {
    gtmId: 'UA-136136607-1'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <div>
    <Favicon url="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1554164415/favicon_lia_B_ekw2ov.png" />
    <App />
  </div>
  , document.getElementById('root'));
serviceWorker.unregister();
