import React, { Component } from 'react';
import Slider from "react-slick";
import MediaQuery from 'react-responsive';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import axios from 'axios';
import './App.css';


class App extends Component {

  constructor(props) {
       super(props);
       this.state = {
         dataEntries: "",
         imgData: "",
         textDataFr: "",
         textDataEn: "",
         textDataImg: "",
         info: false,
         actualColor: "",
         slideIndex: 0,
        updateCount: 0,
        slideColor: "",
        width: null,
        height: null
       };
  }

  componentDidMount(){

    this.targetElement = document.querySelector("#body");
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);

    console.log("Design : Louise Paradis");
    console.log("Code : http://www.75-20.com");

    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});

    axios.get("https://sheet.best/api/sheets/f2ab6e32-a05c-4d00-a6e2-8692c74cb921")
    .then((data) => {
      this.setState({
        dataEntries: data.data,
        imgData: data.data.filter(ele => ele.section === "img"),
        textDataFr: data.data.filter(ele => ele.section === "text_fr"),
        textDataEn: data.data.filter(ele => ele.section === "text_en"),
        textDataImg: data.data.filter(ele => ele.section === "text_img"),

      }, () => {

        console.log(this.state);

        this.setState({
          slideColor: this.state.imgData[0].color_of_index
        })
      })
    })
  }


  updateDimensions = () => {

    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

      this.setState({width: width, height: height}, () => {
        if(this.state.width < 568){
          if(document.getElementsByClassName("slick_container_mobile")[0] !== undefined){
            document.getElementsByClassName("slick_container_mobile")[0].style.overflow = "hidden"
          }
        }
      });

   }


  touchStart(e){
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
}

preventTouch(e){
    const minValue = 5; 
    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;
    if(Math.abs(this.clientX) > minValue){
        e.preventDefault();
        e.returnValue = false;
        return false;
    }
}

  changeHandler = (e) => {
    this.slider.slickNext();
  }


  toggleInfo = () => {
    this.setState({
      info: !this.state.info
    }, () => {
      if(this.state.info === true){
        document.getElementById("body").style.overflow = "scroll";
      }
      if(this.state.info === false){
        document.getElementById("body").style.overflow = "hidden";
        this.setState({
          slideIndex: 0,
          slideColor: this.state.imgData[0].color_of_index
        }, () => {
        })
      }
    })
  }

  render() {
    const settings = {
     dots: false,
     infinite: true,
     speed: 500,
     slidesToShow: 1,
     slidesToScroll: 1,
     afterChange: () =>
       this.setState(state => ({
         updateCount: state.updateCount + 1,
        })),
     beforeChange: (current, next) => this.setState({
       slideIndex: next,
     })
   };

   if(this.state.imgData.length === 0){
     return (
       <div className="App loading">
        <h1>loading</h1>
       </div>
     );
   }else{

     const selectedIndex = this.state.selectedIndex;
     const arrayLength = this.state.imgData.length
     const imgData = this.state.imgData;
     const imgDataToMap = imgData.map((ele, index) => {
       return (
         <div key={index} className="img_container">
         <img
           className="img_mobile"
           onClick={() => this.changeHandler()}
           src={ele.img_link} />
         </div>
       )
     })

     const imgDataToMapMobile = imgData.map((ele, index) => {
       return (
         <div key={index} className="img_container">
         <img
           alt=""
           onClick={() => this.changeHandler()}
           src={ele.img_link_mobile} />
         </div>
       )
     })

    const fr = this.state.textDataFr[0];
    const en = this.state.textDataEn[0];
    const img = this.state.textDataImg[0];
    const emailAddress = fr.email_address;
    const mailto = `mailto:${emailAddress}`

     if(this.state.info === true){

       enableBodyScroll(this.targetElement);

       return (
         <div className="App info">
         <header>
            <div className="header_container">
            <div className="inits">
              <h1
              className="pointer"
              onClick={this.toggleInfo}>
              L.R.
              </h1>
            </div>
            <div className="counter" style={{visibility: "hidden"}}>
              <h1>0/{arrayLength}</h1>
            </div>
            </div>
         </header>
         <section className="text_info">
          <div>
            <p>{fr.main_copy_1}</p>
            <p>{fr.main_copy_2}</p>
            <p>{fr.bottom_copy}
            <span>
              <a href={mailto} target="_blank" rel="noopener noreferrer">
              {emailAddress}
              </a>
            </span>
            </p>
          </div>
          <div>
            <p>{en.main_copy_1}</p>
            <p>{en.main_copy_2}</p>
            <p>{en.bottom_copy}
              <span>
              <a href={mailto} target="_blank" rel="noopener noreferrer">
                {emailAddress}
                </a>
              </span>
            </p>
          </div>
         </section>
         <section className="img_info">
             <img src={img.img_link} />
         </section>
         <div>
         </div>
         </div>
       )
     }else{

      disableBodyScroll(this.targetElement);
       const index = this.state.slideIndex;
       const imgData = this.state.imgData[index];
       const colorOfIndex = imgData.color_of_index;


        return(
          <div className="App img">
              <MediaQuery query="(min-width: 500px)">
              <header>
                <div className="header_container">
                <div className="inits">
                  <h1
                  className="pointer"
                  style={{color: colorOfIndex}}
                  onClick={this.toggleInfo}>
                  L.R.
                  </h1>
                </div>
                <div className="counter">
                  <h1
                  style={{color: colorOfIndex}}>
                  {this.state.slideIndex+1}
                  /
                  {arrayLength}
                  </h1>
                </div>
                </div>
              </header>
              <Slider ref={c => (this.slider = c)} {...settings}>
                {imgDataToMap}
              </Slider>
              </MediaQuery>
              <MediaQuery query="(max-width: 500px)">
              <header>
                <div className="header_container">
                <div className="inits">
                  <h1
                    className="pointer"
                    style={{color: colorOfIndex}}
                  onClick={this.toggleInfo}>
                  L.R.
                  </h1>
                </div>
                <div className="counter">
                  <h1
                    style={{color: colorOfIndex}}>
                  {this.state.slideIndex+1}
                  /
                  {arrayLength}
                  </h1>
                </div>
                </div>
              </header>
              <section
              className="slick_container_mobile">
              <Slider
                ref={c => (this.slider = c)} {...settings}>
                {imgDataToMapMobile}
              </Slider>
              </section>
              </MediaQuery>
          </div>
        )
     }
   }
  }
}


export default App;
